import { defu } from "defu";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

export function useRegistration() {
  const dayjs = useDayjs();
  const { $pinia } = useNuxtApp();
  const { locale, defaultUnitGlucose, defaultUnitEnergy, defaultUnitServing } = useNuxtApp().$i18n;
  const onboardingStore = useOnboardingStore($pinia);
  const { registrationParams } = storeToRefs(onboardingStore);
  const { registerUser } = useBackendApiAppDomainClient();
  const { getWeightChangePerWeek, getEnergyGoal } = useEnergyGoal();

  async function register(password: string, email: string, useruuid?: string) {
    const {
      activityDegree,
      sex,
      bodyHeight,
      startWeight,
      weightGoal,
      dateOfBirth,
      unitLength,
      unitMass,
      goal,
      country,
      userUuid: regParamUserUUID,
      diet,
    } = defu(registrationParams.value as RegistrationParams, onboardingStore.getDefaultPartialRegistrationParams());

    await registerUser({
      uuid: useruuid ?? regParamUserUUID,
      language: locale.value,
      country,
      timezone_offset: dayjs().utcOffset(),
      activity_degree: activityDegree,
      sex,
      weight_change_per_week: getWeightChangePerWeek(startWeight, weightGoal),
      body_height: bodyHeight,
      start_weight: startWeight,
      weight_goal: weightGoal,
      date_of_birth: dateOfBirth,
      registration_device: "web",
      unit_length: unitLength,
      unit_mass: unitMass,
      unit_energy: defaultUnitEnergy.value,
      unit_glucose: defaultUnitGlucose.value,
      unit_serving: defaultUnitServing.value,
      goal,
      energy_goal: getEnergyGoal(sex, startWeight, weightGoal, bodyHeight, dateOfBirth),
      auth: {
        type: "credentials",
        email,
        password,
      },
      dietary_preference: diet,
      calorie_goal_override_mode: null,
    });
  }

  return {
    register,
    registrationParams,
  };
}
